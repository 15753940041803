import { Link } from "gatsby"
import React, { useState } from "react"
import Blob from "./Blob"
import { colors, iconsAreasAtuacao } from "../assets/icons"

const areasAtuacao = [
  {
    name: "Civil",
    description:
      "Interpelações, ações de indemnização por danos materiais e morais, responsabilidade civil, providências cautelares, cobrança de dívidas e procedimentos de injunção, aconselhamento pré-contencioso e análise de risco de processos judiciais, entre outros.",
    icon: "civil",
  },
  {
    name: "Penal e Contra-ordenacional",
    description:
      "Contestação de coimas, elaboração de queixas-crime, acompanhamento do inquérito criminal, interrogatórios, fase instrutória, defesa judicial, recurso penal, entre outros.",
    icon: "penal",
  },
  {
    name: "Trabalho e Segurança Social",
    description:
      "Aconselhamento jurídico especializado, elaboração e revisão de contratos de trabalho, redação de acordos de revogação e cartas de demissão, processos disciplinares, ações de despedimento ilícito, ações para pagamento de créditos laborais, entre outros.",
    icon: "trabalho",
  },
  {
    name: "Família e Menores",
    description:
      "Casamento, separação de pessoas e bens, divórcio, inventário e partilha, regulação das responsabilidades parentais, ação de incumprimento da pensão de alimentos, entre outros.",
    icon: "familia",
  },
  {
    name: "Sucessões, Heranças e Partilhas",
    description:
      "Habilitação de herdeiros, inventário e partilhas, testamentos, entre outros.",
    icon: "sucessao",
  },
  {
    name: "Fiscal",
    description:
      "Reclamação ou impugnação de actos tributários, prescrição de dívidas, contencioso tributário, entre outros.",
    icon: "fiscal",
  },
  {
    name: "Internacional",
    description:
      "Homologação de sentença estrangeira, processo de nacionalidade, vistos e autorização de residência, validação, registo e equivalência de diplomas, entre outros.",
    icon: "internacional",
  },
  {
    name: "Comercial e Empresas",
    description:
      "Consultoria jurídica permanente, constituição de empresas, elaboração e revisão de contratos, cobrança de dívidas, registo de marcas, design e patentes, Registo Central do Beneficiário Efetivo, entre outros.",
    icon: "comercial",
  },
  {
    name: "Imobiliário",
    description:
      "Preparação e acompanhamento de transações imobiliárias como: compra e venda, doação, permuta, arrendamento, locação, dação em pagamento, empreitada, entre outros.",
    icon: "imobiliario",
  },
  {
    name: "Registos e Notariado",
    description:
      "Autenticação de documentos, reconhecimento de assinaturas, certificação de cópias e de traduções, entre outros.",
    icon: "registos",
  },
]

function randomNumberInRange(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const AreasAtuacao = ({ numOfItems }) => {
  const [itemsShow, setItemsShow] = useState(numOfItems)
  return (
    <section id="areas-atuacao" className="areas-atuacao">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <p className="title">
              Atuamos nas mais diversas áreas com{" "}
              <span className="text-primary">rigor</span> e{" "}
              <span className="text-primary">compromisso</span>
            </p>
          </div>
        </div>
        <div className="row">
          {areasAtuacao.slice(0, itemsShow).map((area, key) => (
            <div className="col-6 col-md-4 area-atuacao" key={key}>
              <div className="area-icon-container">
                {iconsAreasAtuacao[area.icon]}
                <div className="area-atuacao-blob">
                  <Blob
                    fill={true}
                    size={80}
                    color={
                      key % 2 === 0 ? colors.secondary : colors.primaryLight
                    }
                    blobShape={randomNumberInRange(0, 4)}
                  />
                </div>
              </div>
              <p className="name">{area.name}</p>
              <p className="description">{area.description}</p>
            </div>
          ))}
        </div>
        {itemsShow < areasAtuacao.length && (
          <p className="text-center">
            <a
              className="main-link"
              onClick={() => setItemsShow(11)}
              role="button"
            >
              Ver mais —
            </a>
          </p>
        )}
      </div>
    </section>
  )
}

export default AreasAtuacao
