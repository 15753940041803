import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { graphql, Link, useStaticQuery } from "gatsby"
import NewsletterSection from "../components/NewsletterSection"
import ListaArtigos from "../components/ListaArtigos"
import AreasAtuacao from "../components/AreasAtuacao"
import SectionHero from "../components/SectionHero"
//import { useLatestBlogPosts } from "../hooks/useLatestBlogPosts"
import Blob from "../components/Blob"
import { colors } from "../assets/icons"

const servicos = [
  "Consulta jurídica",
  "Consultoria jurídica permanente",
  "Acompanhamento judicial e extrajudicial",
  "Elaboração de documentos, cartas e contratos",
  "Preparação e acompanhamento de Escrituras",
  "Legalização de estrangeiros e nacionalidade",
  "Registo de Marcas, Patentes e Designs",
  "Certificação de cópias e traduções",
  "Reconhecimento de assinaturas",
  "Autenticação de documentos",
]

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query LatestBlogPostsQuery {
      allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
        edges {
          node {
            id
            title
            slug
            excerpt
            uri
            featuredImage {
              node {
                mediaItemUrl
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
            date
            categories {
              nodes {
                name
              }
            }
          }
        }
      }
    }
  `)

  const latestBlogPosts = data.allWpPost.edges
  const heroImage = (
    <StaticImage src="../images/hero-bg.jpeg" alt="mmv_heroimage" />
  )

  return (
    <>
      <Seo title="Home" />

      <SectionHero
        title="Confiança. Transparência. Proximidade."
        text="Um melhor acompanhamento, uma melhor defesa."
        image={heroImage}
        secondTextRight={false}
      />

      <section id="sobre-nos" className="home-about">
        <div className="container">
          <p className="mini-text">Sobre nós</p>
          <p className="description">
            O compromisso é claro:{" "}
            <span className="text-primary">defender</span> os seus direitos e
            interesses como sendo os nossos.
          </p>
        </div>

        <div className="about-me">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <StaticImage
                  src="../images/maria-manuel-vieira.jpeg"
                  alt="maria manuel vieira"
                />
              </div>
              <div className="col-md-6 offset-md-1">
                <p className="title">Maria Manuel Vieira</p>
                <p className="labels">
                  Advogada — Criminóloga — Mestre em Direito Judiciário
                </p>
                <p className="bio">
                  Eclética, determinada e perfeccionista. <br />3 palavras que
                  definem um espírito empreendedor e dinâmico que me
                  caracteriza.
                </p>
                <Link to="/sobre-nos" className="main-link">
                  Conhecer melhor —
                </Link>
              </div>
            </div>

            <div className="blob">
              <Blob
                size={200}
                color={colors.primaryLight}
                blobShape={1}
                borderWidth={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="servicos" className="home-services">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <p className="title">Conheça os nossos Serviços</p>
              <p className="description">
                Para além das nossas áreas de atuação, dispomos de um vasto
                leque de serviços para lhe oferecer:
              </p>
            </div>
            <div className="col-md-6 offset-md-1">
              <ul className="servicos">
                {servicos.map((servico, key) => (
                  <li key={key}>{servico}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <AreasAtuacao numOfItems={6} />

      <NewsletterSection />

      <ListaArtigos
        title="Artigos"
        showCategories={false}
        isShort={true}
        artigosList={latestBlogPosts}
      />
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
